import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {useSettings} from '../../../hooks/settings'
import settingsParams from '../../../settingsParams'
import {Description} from '../styled-components/description'
import {PageTitle} from '../styled-components/page-title'
import s from './header.scss'

export const Header = () => {
  const settings = useSettings()
  const title = settings.get(settingsParams.pageTitle)
  const subtitle = settings.get(settingsParams.description)

  return (
    <div className={s.root}>
      <div className={s.title}>
        <PageTitle data-hook={DH.APP_HEADER_TITLE}>{title}</PageTitle>
      </div>
      <div className={s.subtitle}>
        <Description data-hook={DH.APP_HEADER_DESCRIPTION}>{subtitle}</Description>
      </div>
    </div>
  )
}
