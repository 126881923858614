import {getEventId} from '@wix/wix-events-commons-statics'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {isTicketedWithNoOrder} from '../../../selectors/event'
import {downloadTickets, downloadTicketsWithNoOrder} from '../../../services/tickets'
import {EventLink} from '../../styled-components/event-link'
import {DownloadTicketsButtonProps} from './index'

export const DownloadTicketsButton: React.FC<DownloadTicketsButtonProps> = ({
  mobile,
  event,
  tickets,
  downloadTicketsAction,
  t,
  order,
}) => {
  const eventId = getEventId(event.event)
  const ticketedWithNoOrder = isTicketedWithNoOrder(event)
  const eventTickets = tickets[eventId]

  const handleDownload = () => {
    ticketedWithNoOrder ? downloadTicketsWithNoOrder(eventTickets) : downloadTickets(order)
    downloadTicketsAction(eventId)
  }

  return (
    <EventLink
      secondary={mobile}
      mobile={mobile}
      onClick={handleDownload}
      data-hook={DH.BUTTON_DOWNLOAD_TICKETS}
      disabled={ticketedWithNoOrder && !eventTickets}
    >
      {t('events.buttons.download-tickets')}
    </EventLink>
  )
}
